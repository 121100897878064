<template>
  <div class="page">
    <Navbar title="微客邀请" type="SMP" />
    <van-search
      v-model="value"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
      @cancel="onCancel"
    />
    <div class="advice" @click="payment()">
      <van-image
        class="img"
        :src="require('../../../assets/images/seller/adv-02.jpg')"
      ></van-image>
    </div>
    <van-tabs :active="auditType" :color="COLOR" border="true">
      <van-tab title="已审核" name="yvf"
        ><div class="seller" v-for="seller in sellers" :key="seller.identify">
          <van-row>
            <van-col span="24">
              <van-row>
                <van-col span="24" class="name"
                  >{{ seller.name }}({{ seller.identify }})
                  <van-tag
                    type="primary"
                    :color="COLOR"
                    v-if="seller.type === 'PSN'"
                    >个人</van-tag
                  ></van-col
                >
              </van-row>
              <van-row>
                <van-col span="24" class="text"
                  >运营商：{{ seller.operatorName }}</van-col
                >
              </van-row>
              <van-row>
                <van-col span="24" class="text"
                  >申请时间：{{ seller.time }}</van-col
                >
              </van-row>
            </van-col>
          </van-row>
        </div>
        <van-row class="no-record" v-if="sellers.length <= 0">
          <van-col span="24">
            <van-image
              width="103"
              height="103"
              :src="require('../../../assets/images/home/no-record.png')"
            ></van-image
          ></van-col>
        </van-row>
      </van-tab>
      <van-tab title="待审核" name="nvf"
        ><div class="seller" v-for="apply in applys" :key="apply.identify">
          <van-row>
            <van-col span="24">
              <van-row>
                <van-col span="24" class="name"
                  >{{ apply.name }}({{ apply.identify }})
                  <van-tag
                    type="primary"
                    :color="COLOR"
                    v-if="apply.type === 'PSN'"
                    >个人</van-tag
                  ></van-col
                >
              </van-row>
              <van-row>
                <van-col span="24" class="text"
                  >运营商：{{ apply.operatorName }}</van-col
                >
              </van-row>
              <van-row>
                <van-col span="24" class="text"
                  >申请时间：{{ apply.time }}</van-col
                >
              </van-row>
            </van-col>
          </van-row>
        </div>
        <van-row class="no-record" v-if="applys.length <= 0">
          <van-col span="24">
            <van-image
              width="103"
              height="103"
              :src="require('../../../assets/images/home/no-record.png')"
            ></van-image
          ></van-col>
        </van-row>
      </van-tab>
    </van-tabs>
    <div class="operate">
      <van-row>
        <van-col span="24">
          <van-button
            class="btn"
            icon="plus"
            :color="COLOR"
            size="small"
            @click="shareShow = true"
            >立即邀请</van-button
          >
        </van-col>
      </van-row>
    </div>
    <van-popup
      :show="shareShow"
      @click-overlay="shareShow = false"
      @click="shareShow = false"
      style="background-color: transparent; width: 100%; height: 100%"
    >
      <img
        class="shareImg"
        :src="require('../../../assets/images/online/share-top.png')"
      />
    </van-popup>
    <Share ref="share" />
  </div>
</template>
<script>
import Navbar from '../../module/common/Navbar.vue'
import Share from '../../module/common/Share.vue'
import { Search, Image, Popup, Tabs, Tab, Tag, Icon } from 'vant'
export default {
  components: {
    Share: Share,
    Navbar: Navbar,
    [Image.name]: Image,
    [Search.name]: Search,
    [Popup.name]: Popup,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Tag.name]: Tag,
    [Icon.name]: Icon
  },
  data () {
    return {
      auditType: 'yvf',
      shareShow: false,
      applys: [],
      sellers: []
    }
  },
  mounted () {
    this.retrieveApplyList()
    this.retrieveSellerList()
    this.initShare()
  },
  methods: {
    async retrieveApplyList () {
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var pd = { inviterUser: user }
      const { data: res } = await this.$http.post(this.UMS_URL + '/sell/apply/retrieveInviterApplyList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.applys = res.data
      }
    },
    async retrieveSellerList () {
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var pd = { inviterUser: user }
      const { data: res } = await this.$http.post(this.UMS_URL + '/sell/seller/retrieveInviterSellerList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.sellers = res.data
      }
    },
    resumeView (code) {
      this.$router.push({ path: '/person/resumeView', query: { personCode: code } })
    },
    initShare () {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var title = '微职客：邀请您加入微客'
      var desc = '注册[微客]可以对工作、证书等任务进行转发分享,订单完成后可以获得订单分成奖励'
      var link = 'https://moc.utopanet.com/seller/apply?inviterSeller=' + seller + '&inviterUser=' + user
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.$refs.share.init(title, desc, link, logo)
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  padding-bottom: 50px;
  ::v-deep .van-tabs--line .van-tabs__wrap {
    height: 34px;
  }
}
.title {
  height: 30px;
  line-height: 30px;
  text-align: left;
  font-size: 14px;
  font-weight: 800;
  padding-left: 10px;
}
.seller {
  padding: 10px 10px;
  border-bottom: 1px solid #eee;
  .name {
    text-align: left;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 600;
    height: 20px;
    line-height: 20px;
  }
  .text {
    text-align: left;
    padding-left: 10px;
    height: 18px;
    line-height: 18px;
  }
}
.operate {
  padding: 5px 0px;
  background-color: #fff;
  height: 40px;
  position: fixed;
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  bottom: 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #bbb;
}
.shareImg {
  width: 90%;
}
.advice {
  width: 100%;
}
</style>
